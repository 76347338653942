import { createClient, ServerError } from "@sanity/client";
import { Log } from "./helpers/services/LoggingService";
import { LoggingSeverity } from "./helpers/enums/LoggingSeverity";

export const SanityClient = createClient({
	projectId: String(process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID),
	dataset: String(process.env.REACT_APP_SANITY_STUDIO_DATASET),
	apiVersion: "2024-01-01",
	useCdn: false,
});

export const GetData = (Data : {Default : any[], Locale : any[]}, Type : string, Field : string ) => {
	try{
		if (Data.Locale.length > 0){
			return Data.Locale.filter(e => e['_type'] === Type)[0][Field];
		}else{
			throw new Error("Main locale data not found");
		}
	}catch(exception){
		Log(LoggingSeverity.Warning, "client.ts", (exception as Error).message, `Type: ${Type}, Field: ${Field}`);
		return GetFallbackData(Data.Default, Type, Field);
	}
}

const GetFallbackData = (Default : any[], type : string, field : string) => {
	if(Default.length > 0){
		return Default.filter(e => e['_type'] === type)[0][field];
	}else{
		Log(LoggingSeverity.Warning, "client.ts", "Fallback locale data not found");
		return "";
	}
}