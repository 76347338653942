//#region React Imports
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
//#endregion

//#region MUI Imports
import TextField from '@mui/material/TextField';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
//#endregion

//#region Component Imports
import Section from "./Section";
//#endregion

//#region CSS Imports
import "../../css/serviceapp/template-editor.css";
//#endregion

//#region Helper Imports
import { ITemplateEditorText } from "../../helpers/interfaces/LocaleObjects";
import { TemplateDto } from "../../helpers/dtos/TemplateDto";
import { SectionDto } from "../../helpers/dtos/SectionDto";
//#endregion

//#region Interfaces
// Props Interface for TemplateEditor component
interface TemplateEditorProps {
    LocaleText: ITemplateEditorText;
    ListOfTypes: string[];
    Template: TemplateDto;
    SetTemplate: React.Dispatch<React.SetStateAction<TemplateDto>>;
    CancelButtonBehavior: () => void;
    SaveButtonBehavior: (template: TemplateDto) => void;
}
//#endregion

// Component function "TemplateEditor"
export default function TemplateEditor(Props: TemplateEditorProps) {
    //#region State Management
    // Initialize template state
    const [template, setTemplate] = [Props.Template, Props.SetTemplate];
    const [sections, setSections] = useState<SectionDto[]>(template.sections);

    useEffect(() => {
        var updatedSections = sections.map((section, i) => {
            if(!section.id) section.id = uuidv4();
            if(!section.index) section.index = i;
            return section;
        })

        setTemplate({...template, sections: updatedSections})
    }, [sections])
    //#endregion

    //#region Section Management Functions
    /**
     * Creates a new section and adds it to the template
     */
    const CreateSection = () => {
        // Gets the latest index #
        var index = template.sections.length;

        // Generate UUID
        var id = uuidv4();

        // Create sectionValue object (holds the data of the sections) for this new section being created
        const section: SectionDto = {
            id: id,
            index: index,
            fieldName: "",
            fieldType: Props.ListOfTypes[0]
        };

        var newSections = template.sections;
        newSections.push(section);

        console.log(template)
        console.log(newSections)

        // Update the state that holds the list of sectionValues of the page
        setSections(newSections);
    }

    /**
     * Deletes a section by its ID and adjusts indices
     * @param id The ID of the section to delete
     */
    const DeleteSection = (id: string) => {
        // Define a number to handle the index shifts
        /// For example if you delete index 3 then index 4 has to shift up to take its place
        var shiftFrom: number = 0;

        // Set the new section values list
        /// First sort it in asc order
        /// then remove the element with the specified id in param
        /// then shift the rest of the indices
        const newSections = template.sections
            .sort((a, b) => a.index - b.index)
            .filter((e, i) => {
                if (e.id === id) {
                    shiftFrom = e.index + 1;
                    return false;
                }
                return true;
            })
            .map((e) => {
                if (e.index >= shiftFrom) {
                    return { ...e, index: e.index - 1 };
                }
                else {
                    return e;
                }
            });

        // Set new section values list
        setSections(newSections);
    }

    /**
     * Moves a section up or down in the template
     * @param index The current index of the section
     * @param direction The direction to move ("up" or "down")
     */
    const MoveSection = (index: number, direction: "up" | "down") => {

        // When direction to move is "up"
        if (direction === "up") {

            // Find the ids of the current and previous sections
            var current = template.sections.find((e) => e.index === index)?.id;
            var previous = template.sections.find((e) => e.index === index - 1)?.id;

            // Set the new section values list
            /// swap the current and previous' indices
            const newSections = template.sections.map((e) => {
                if (e.id === current) {
                    return { ...e, index: e.index - 1 };
                }
                else if (e.id === previous) {
                    return { ...e, index: e.index + 1 };
                }
                else {
                    return e;
                }
            });

            // Set new section values to the state list
            setSections(newSections);
        }

        // When direction to move is "down"
        if (direction === "down") {

            // Find ids of current and next sections
            var current = template.sections.find((e) => e.index === index)?.id;
            var next = template.sections.find((e) => e.index === index + 1)?.id;

            // Create new updated section list
            /// Swap current and next indices 
            const newSections = template.sections.map((e) => {
                if (e.id === current) {
                    return { ...e, index: e.index + 1 };
                }
                else if (e.id === next) {
                    return { ...e, index: e.index - 1 };
                }
                else {
                    return e;
                }
            });

            // Set section values list with new updated list
            setSections(newSections);
        }
    };
    //#endregion

    //#region Render
    // Component's JSX value
    return (
        <Row className={"template-editor-main justify-content-md-center"}>

            {/** First row with the Cancel and Save buttons */}
            <Row className={"template-editor-buttons justify-content-md-center"}>
                <Col xs={{ span: 3, offset: 3 }}>
                    <ClearRoundedIcon
                        className="template-editor-clearIcon template-editor-button"
                        aria-label={Props.LocaleText.TemplateCancelButtonText}
                        titleAccess={Props.LocaleText.TemplateCancelButtonText}
                        onClick={Props.CancelButtonBehavior}
                    />
                    <CheckRoundedIcon
                        className="template-editor-saveIcon template-editor-button"
                        aria-label={Props.LocaleText.TemplateSaveButtonText}
                        titleAccess={Props.LocaleText.TemplateSaveButtonText}
                        onClick={() => Props.SaveButtonBehavior(template)}
                    />
                </Col>
            </Row>

            {/** Second row with the Template Name field */}
            <Row className={"template-editor-title justify-content-md-center"}>
                <Col xs={{ span: 6 }}>
                    <TextField required
                        className={"template-editor-title-field"}
                        size={"small"} id={"outlined-required"}
                        label={Props.LocaleText.TemplateNameLabelText}
                        defaultValue={Props.LocaleText.TemplateNameDefaultValue}
                        onChange={(e) => {
                            setTemplate({ ...template, name: e.target.value })
                        }}
                    />
                </Col>
            </Row>

            {/** Generate Section react components based on the list of section values */}
            {
                sections
                    ? sections
                        .sort((a, b) => a.index - b.index)
                        .map((section, i) => (
                            <Section
                                key={i}
                                Index={section.index}
                                Id={section.id}

                                MoveSectionUpBehavior={() => MoveSection(section.index, "up")}
                                MoveSectionDownBehavior={() => MoveSection(section.index, "down")}
                                DeleteSectionBehavior={() => DeleteSection(section.id)}

                                Sections={sections}
                                SetSections={setSections}

                                FieldNameLabelText={Props.LocaleText.SectionFieldNameLabelText}
                                FieldTypeLabelText={Props.LocaleText.SectionFieldTypeLabelText}
                                FieldTypeValues={Props.ListOfTypes}
                            />
                        ))
                    : ""
            }

            {/** Last row with the button to create new section */}
            <Row className="template-editor-section-new justify-content-md-center">
                <Col xs={6} className="template-editor-section-new-button" onClick={() => CreateSection()}>
                    {Props.LocaleText.TemplateCreateNewFieldLabelText}
                </Col>
            </Row>

        </Row>
    );
    //#endregion
}