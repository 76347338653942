//#region Imports
import React from "react";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import { MenuItem } from "@mui/material";
import "../../css/serviceapp/section.css";

// Icons
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

// DTOs
import { SectionDto } from "../../helpers/dtos/SectionDto";
//#endregion

//#region Interfaces
interface SectionProps{
    Id : string;
    Index : number;
    MoveSectionUpBehavior() : any;
    MoveSectionDownBehavior() : any;
    DeleteSectionBehavior() : any;
    Sections: SectionDto[];
    SetSections: React.Dispatch<React.SetStateAction<SectionDto[]>>;
    FieldNameLabelText : string;
    FieldTypeLabelText : string;
    FieldTypeValues : string[];
}
//#endregion

export default function Section(Props:SectionProps){
    //#region Component State
    const isFirstElement = Props.Index === 0;
    const IsLastElement = Props.Index === Props.Sections.length-1;
    const [sections, setSections] = [Props.Sections, Props.SetSections];
    //#endregion

    //#region Event Handlers
    /**
     * Update SectionValues state list when Field Name is updated
     * @param e Change event from text field
     */
    const NameChanged = (e: any) =>{
        setSections(prevSections => {
            const updatedSections = prevSections.map((value) =>
                value.id === Props.Id
                    ? { ...value, fieldName: e.target.value }
                    : value
            );
            return updatedSections;
        });
    }

    /**
     * Update SectionValues state list when Field Type is updated
     * @param e Change event from select field
     */
    const TypeChanged = (e: any) => {
        setSections(prevSections => {
            const updatedSections = prevSections.map((value) =>
                value.id === Props.Id
                    ? { ...value, fieldType: e.target.value }
                    : value
            );
            return updatedSections;
        });
    };
    //#endregion

    //#region Render Component
    return (
        <Row key={Props.Index} className={"section-main justify-content-md-center"}>
            {/** Navigation Controls */}
            <Col xs={1} className="section-icon-wrapper">
                <div 
                    className="section-icon section-icon-uparrow" 
                    onClick={!isFirstElement ? Props.MoveSectionUpBehavior : () => {}}>
                        <KeyboardArrowUpRoundedIcon color={isFirstElement ? "disabled" : "inherit"}/>
                </div>
                <div 
                    className="section-icon section-icon-downarrow" 
                    onClick={!IsLastElement ? Props.MoveSectionDownBehavior : () => {}}>
                        <KeyboardArrowDownRoundedIcon color={IsLastElement ? "disabled" : "inherit"}/>
                </div>
            </Col>

            {/** Field Name Input */}
            <Col xs={3} className="section-field-wrapper" id="left">
                <TextField required
                    className={"section-field-name"}
                    size="small"
                    id="outlined-required"
                    label={Props.FieldNameLabelText}
                    value={sections.find((e) => e.id === Props.Id)?.fieldName}
                    onChange={NameChanged}/>
            </Col>

            {/** Field Type Selector */}
            <Col xs={3} className="section-field-wrapper" id="right">
                <TextField required select
                    className={"section-field-type"}
                    size="small"
                    id="outlined-required"
                    label={Props.FieldTypeLabelText}
                    value={sections.find((e) => e.id === Props.Id)?.fieldType}
                    onChange={TypeChanged}>
                        {
                            Props.FieldTypeValues.map((e, i)=>{
                                return (
                                    <MenuItem   
                                        className="section-field-type-menuitem"
                                        key={"field-type-"+i} 
                                        value={e}>
                                        {e}
                                    </MenuItem>
                                )
                            })
                        }
                </TextField>
            </Col>

            {/** Delete Control */}
            <Col xs={1} className="section-icon-wrapper" id="right">
                <div 
                    className="section-icon section-icon-delete"
                    onClick={Props.DeleteSectionBehavior}>
                        <DeleteForeverOutlinedIcon style={{color:"#f08080"}}/>
                </div>
            </Col>
        </Row>
    );
    //#endregion
}