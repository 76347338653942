import { ICommonTableData } from "../interfaces/ICommonTableData";
import { AutoMap } from "@automapper/classes";

export class CommonTableData implements ICommonTableData{
    [key: string]: any;

    @AutoMap()
    id: string = "";

    @AutoMap()
    name: string = "";
    
    @AutoMap()
    createdOn: string = "";
}