//#region Imports
// React imports
import React from "react";

// Component imports
import CommonTable from "../common/Table";
import { Col, Row } from "react-bootstrap";

// Style imports
import "../../css/serviceapp/template-table.css";

// Icon imports
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

// Type imports
import { ITemplateTableText } from "../../helpers/interfaces/LocaleObjects";
import { TemplateDto } from "../../helpers/dtos/TemplateDto";
import { Mapper } from "../../helpers/mappers/Mappers";
import { CommonTableData } from "../../helpers/dataobj/CommonTableData";
//#endregion

//#region Interfaces
interface TemplateTableProps{
    TableData: TemplateDto[],
    LocaleText: ITemplateTableText,
    TableDeleteRow: (idList : string[]) => void,
    TableCreateRow: () => void,
    TableEditRow: (id : string) => void,
    TableGoBack: () => void
}
//#endregion

/**
 * TemplateTable Component
 * 
 * Displays a table of templates with actions for creating, editing, and deleting entries.
 * Includes navigation controls and row selection functionality.
 * 
 * @param Props - Component properties containing table data, localization text, and callback functions
 */
export default function TemplateTable(Props: TemplateTableProps){
    //#region State
    const [selectedRow, setSelectedRow] = React.useState<string[]>([]);
    //#endregion

    //#region Render
    return <Row className={"template-table-main justify-content-md-center"}>
        {/* Action Buttons */}
        <Row className="template-table-buttons justify-content-md-center">
            <Col xs={{span:1}}>
                <ArrowBackRoundedIcon
                    className="template-table-backButton template-table-button"
                    aria-label={Props.LocaleText.BackButtonAria}
                    titleAccess={Props.LocaleText.BackButtonAria}
                    onClick={Props.TableGoBack}
                />
            </Col>
            <Col xs={{offset:5, span:2}} className="text-end">
                <AddBoxOutlinedIcon
                    className="template-table-addButton template-table-button"
                    aria-label={Props.LocaleText.AddButtonAria}
                    titleAccess={Props.LocaleText.AddButtonAria}
                    onClick={Props.TableCreateRow}
                />    
                <ModeEditOutlineOutlinedIcon
                    className={
                        "template-table-editButton template-table-button " 
                        + (
                            selectedRow.length !== 1 
                                ? "template-table-editButton-disabled" 
                                : ""
                        )
                    }
                    aria-label={Props.LocaleText.EditButtonAria}
                    titleAccess={Props.LocaleText.EditButtonAria}
                    onClick={
                        selectedRow.length === 1 
                            ? () => Props.TableEditRow(selectedRow[0])
                            : () => {}
                    }
                />
                <DeleteForeverOutlinedIcon
                    className={
                        "template-table-deleteButton template-table-button " 
                        + (
                            selectedRow.length === 0 
                                ? "template-table-deleteButton-disabled" 
                                : ""
                        )
                    }
                    aria-label={Props.LocaleText.DeleteButtonAria}
                    titleAccess={Props.LocaleText.DeleteButtonAria}
                    onClick={
                        selectedRow.length !== 0 
                            ? () => Props.TableDeleteRow(selectedRow)
                            : () => {}
                    }
                />
            </Col>
        </Row>

        {/* Table Content */}
        <Row className={"template-table-table justify-content-md-center"}>
            <Col xs={{span:8}}>
                <CommonTable LocaleText={Props.LocaleText} TableData={Props.TableData.map((data) => Mapper.map(data, TemplateDto, CommonTableData))} StateSelectedRow={selectedRow} SetStateSelectedRow={setSelectedRow}/>
            </Col>
        </Row>
    </Row>;
    //#endregion
}