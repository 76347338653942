import { createMapper, MappingConfiguration } from '@automapper/core';
import { classes } from '@automapper/classes';
import { createMap, forMember, mapFrom } from '@automapper/core';

import { SectionContract, TemplateContract } from '../contracts/TemplateContract';
import { TemplateDto } from '../dtos/TemplateDto';
import { SectionDto } from '../dtos/SectionDto';
import { CommonTableData } from '../dataobj/CommonTableData';

// Create and export the mapper
export const Mapper = createMapper({
    strategyInitializer: classes(),
});

export const MapperInit = () => {
    
    createMap(
        Mapper,
        SectionContract,
        SectionDto,
        forMember(d => d.fieldName, mapFrom(s => s.fieldName)),
        forMember(d => d.fieldType, mapFrom(s => s.fieldType))
    );

    createMap(
        Mapper,
        TemplateContract,
        TemplateDto,
        forMember(d => d.id, mapFrom(s => s.id)),
        forMember(d => d.name, mapFrom(s => s.name)),
        forMember(d => d.sections, mapFrom(s => s.sections))
    );

    createMap(
        Mapper, 
        TemplateDto, 
        CommonTableData,
        forMember(d => d.id, mapFrom(s => s.id)),
        forMember(d => d.name, mapFrom(s => s.name)),
        forMember(d => d.createdOn, mapFrom(s => "Now"))
    );

    createMap(
        Mapper, 
        TemplateDto, 
        TemplateContract,
        forMember(d => d.id, mapFrom(s => s.id)),
        forMember(d => d.name, mapFrom(s => s.name)),
        forMember(d => d.sections, mapFrom(s => s.sections))
    );

    createMap(
        Mapper, 
        SectionDto, 
        SectionContract,
        forMember(d => d.fieldName, mapFrom(s => s.fieldName)),
        forMember(d => d.fieldType, mapFrom(s => s.fieldType))
    );
    
}