//#region Imports
import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from "react-bootstrap";
import "../css/footer.css";
//#endregion

//#region Interfaces
/**
 * Properties for the Footer component
 * @property {string} CreditText - Text to display in the footer
 * @property {string} BackgroundColor - Background color of the footer
 * @property {string} FooterTextColor - Color of the text in the footer
 */
interface FooterProps {
    CreditText: string;
    BackgroundColor: string;
    FooterTextColor: string;
}
//#endregion

//#region Component
/**
 * Footer component that adapts to page content and window size
 * @param {FooterProps} Props - The properties for the footer
 * @returns {JSX.Element} Rendered footer component
 */
export default function Footer(Props: FooterProps) {
    //#region State
    const [isSticky, setIsSticky] = useState(false);
    const footerRef = useRef<HTMLElement>(null);
    //#endregion

    //#region Effects
    /**
     * Handle footer positioning based on page content and window size
     */
    useEffect(() => {
        const handleResize = () => {
            const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;
            setIsSticky(window.innerHeight >= (document.body.scrollHeight + footerHeight));
        };

        const observer = new MutationObserver(handleResize);
        observer.observe(document.body, { childList: true, subtree: true, attributes: true });

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            observer.disconnect();
        };
    }, []);
    //#endregion

    //#region Render
    return (
        <Row 
            className={`justify-content-md-center footer-main ${isSticky ? 'sticky-footer' : ''}`} 
            style={{backgroundColor: Props.BackgroundColor}}
            ref={footerRef}
        >
            <Col xs={6}>
                <div 
                    className="footer-credit-text" 
                    style={{color: Props.FooterTextColor}}>
                    {Props.CreditText}
                </div>
            </Col>
        </Row>
    );
    //#endregion
}
//#endregion
