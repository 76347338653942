//#region Node Imports
import React, { useEffect, useState } from "react";
import {Container} from "react-bootstrap";
//#endregion

//#region Component Imports
import LogoImage from "../../icon/temp/temp-icon-colour.png";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import TemplateTable from "../../components/serviceapp/TemplateTable";
import CommonAlert from "../../components/common/Alert";
//#endregion

//#region Locale Imports
import { GetData } from "../../client";
import {
    API_GET_TEMPLATE,
    API_SERVICE_APP,

    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_SERVICEAPPNAME,

    ROUTE_EDIT,

    ROUTE_LIST,
    ROUTE_NEW,
    ROUTE_SERVICE,
    ROUTE_SERVICEAPP,
    ROUTE_TEMPLATE,

    TEMPLATE_TABLE,
    TEMPLATE_TABLE_ADDBUTTONARIA,
    TEMPLATE_TABLE_BACKBUTTONARIA,
    TEMPLATE_TABLE_DELETEBUTTONARIA,
    TEMPLATE_TABLE_EDITBUTTONARIA,
    TEMPLATE_TABLE_TABLECREATEDONHEADERTEXT,
    TEMPLATE_TABLE_TABLENAMEHEADERTEXT,
    TEMPLATE_TABLE_TABLETYPEHEADERTEXT,
} from "../../helpers/GlobalConstants";
//#endregion

//#region Helper Imports
import { ITemplateTableText } from "../../helpers/interfaces/LocaleObjects";
import { TemplateContract } from "../../helpers/contracts/TemplateContract";
import { DeleteTemplates, GetTemplates } from "../../helpers/services/TemplateWebService";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Mapper } from "../../helpers/mappers/Mappers";
import { TemplateDto } from "../../helpers/dtos/TemplateDto";
import { TemplateActionResponseDto } from "../../helpers/dtos/TemplateActionResponseDto";
//#endregion

export default function ListOfTemplatesPage(Props:any){

    //#region Locale Data
    const data = Props.Data;
    const serviceAppName = GetData(data, NAVIGATION, NAVIGATION_SERVICEAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    var locale : ITemplateTableText = {
        BackButtonAria: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_BACKBUTTONARIA),
        AddButtonAria: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_ADDBUTTONARIA),
        EditButtonAria: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_EDITBUTTONARIA),
        DeleteButtonAria: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_DELETEBUTTONARIA),
        name: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_TABLENAMEHEADERTEXT),
        createdOn: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_TABLECREATEDONHEADERTEXT),
        type: GetData(data, TEMPLATE_TABLE, TEMPLATE_TABLE_TABLETYPEHEADERTEXT)
    }
    //#endregion

    //#region Constants
    const endpointUrl:string = String(process.env.REACT_APP_LITURGY_APP_BACKEND_URL);
    const navigate = useNavigate();
    const location = useLocation();
    //#endregion

    //#region States
    const [templates, setTemplates] = useState<TemplateContract[]>([]);
    const [shouldAlert, setShouldAlert] = useState<boolean>(location.state !== null || false);
    //#endregion

    //#region Effects
    useEffect(() => {
        GetTemplates(endpointUrl, API_SERVICE_APP, API_GET_TEMPLATE, setTemplates);
    }, []);
    //#endregion

    //#region Alert Functions
    /**
     * Retrieves the alert message from location state
     * @returns Alert message string
     */
    const getAlertMessage = () => {
        var alertData = location.state as TemplateActionResponseDto;
        return alertData.message;
    }

    /**
     * Determines the alert status based on success flag
     * @returns 'success' or 'error' string
     */
    const getAlertStatus = () => {
        var alertData = location.state as TemplateActionResponseDto;
        return alertData.success
            ? "success"
            : "error";
    }
    //#endregion

    //#region Template Management Functions
    /**
     * Deletes selected templates by their IDs
     * @param idList Array of template IDs to delete
     */
    const deleteTemplates = async (idList : string[]) => {
        DeleteTemplates(endpointUrl, API_SERVICE_APP, API_GET_TEMPLATE, idList, setTemplates);
    }

    /**
     * Navigates to template edit page with selected template data
     * @param id Template ID to update
     */
    const updateTemplates = async (id : string) => {
        const template = templates.find(data => data.id === id);
        if (template) {
            navigate(ROUTE_SERVICEAPP + ROUTE_TEMPLATE + ROUTE_EDIT, { state: template });
        }
    }
    //#endregion

    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#4b5648" 
                BackgroundCircle="#fffaeb" 
                NavigationTextColor="#fffaeb" 
                AppName={serviceAppName}/>
            {
                shouldAlert
                    ? <CommonAlert 
                        severity={getAlertStatus()} 
                        variant="outlined" 
                        alertText={getAlertMessage()} 
                        secondsBeforeClose={8} 
                        shouldClose={true} />
                    : null
            }
            <TemplateTable 
                TableData={templates.map((data) => Mapper.map(data, TemplateContract, TemplateDto))}
                LocaleText={locale}
                TableCreateRow={() => navigate(ROUTE_SERVICEAPP+ROUTE_TEMPLATE+ROUTE_NEW)}
                TableEditRow={updateTemplates}
                TableGoBack={() => navigate(ROUTE_SERVICEAPP+ROUTE_SERVICE+ROUTE_LIST)}
                TableDeleteRow={deleteTemplates}
            />
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#fffaeb"></Footer>
        </Container>
    );
}
