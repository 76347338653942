import { ResponseContract } from "../contracts/ResponseContract";
import { TemplateContract } from "../contracts/TemplateContract";
import { LoggingSeverity } from "../enums/LoggingSeverity";
import { Log } from "./LoggingService";
import { Get, Post, Put } from "./WebService";

export async function GetTemplates(
    url: string, 
    appName: string, 
    callName: string,
    setState: React.Dispatch<React.SetStateAction<TemplateContract[]>>
) {
    try {
        const fetchedData : TemplateContract[] = await Get(url, appName, callName);

        if(!fetchedData){
            Log(LoggingSeverity.Warning, "TestTemplateTable.GetTemplates()", "Fetched data is empty");
            setState([]);
            return;
        }

        setState(fetchedData);

    } catch (error) {
        Log(LoggingSeverity.Error, "TestTemplateTable.GetTemplates()", "Error in fetching data", (error as Error).message);
        throw error;
    }
}

export async function DeleteTemplates(
    url: string, 
    appName: string, 
    callName: string,
    idList: string[],
    setState: React.Dispatch<React.SetStateAction<TemplateContract[]>>
) {
    try {
        Log(LoggingSeverity.Error, "TemplateWebService.DeleteTemplates()", "Delete Data call not implemented yet", "ID List: " + idList);

    } catch (error) {
        Log(LoggingSeverity.Error, "TestTemplateTable.DeleteTemplates()", "Error in deleting data", (error as Error).message);
        throw error;
    }
}

export async function CreateTemplate(
    url: string, 
    appName: string, 
    callName: string,
    template: TemplateContract
) {
    try {
        const response : ResponseContract = await Post<ResponseContract>(url, appName, callName, template);

        if(!response || !response.success){
            Log(LoggingSeverity.Warning, "TestTemplateTable.CreateTemplate()", "Failed to create template via POST call");
        }

        Log(LoggingSeverity.Debug, "TestTemplateTable.CreateTemplate()", "Template created successfully", "Success: " + response.success);

        return response;
    } catch (error) {
        Log(LoggingSeverity.Error, "TestTemplateTable.CreateTemplate()", "Error in creating data", (error as Error).message);
        throw error;
    }
}

export async function UpdateTemplate(
    url: string, 
    appName: string, 
    callName: string,
    template: TemplateContract
) {
    try {
        const response : ResponseContract = await Put<ResponseContract>(url, appName, callName, template);

        if(!response || !response.success){
            Log(LoggingSeverity.Warning, "TestTemplateTable.CreateTemplate()", "Failed to create template via POST call");
        }

        Log(LoggingSeverity.Debug, "TestTemplateTable.CreateTemplate()", "Template created successfully", "Success: " + response.success);

        return response;
    } catch (error) {
        Log(LoggingSeverity.Error, "TestTemplateTable.CreateTemplate()", "Error in creating data", (error as Error).message);
        throw error;
    }
}