//#region Imports
import React from "react";
import { Col, Row } from "react-bootstrap";
import "../css/navigation.css";
//#endregion

//#region Interfaces
/**
 * Props for the Navigation component
 * @property LogoImage - URL of the logo image
 * @property BackgroundColour - Background color of the navigation bar
 * @property AppName - Name of the application
 * @property BackgroundCircle - Background color of the logo circle
 * @property NavigationTextColor - Color of the navigation text
 * @property Sticky - Whether the navigation bar should stick to the top
 */
interface NavigationProps{
    LogoImage: string;
    BackgroundColour: string;
    AppName: string;
    BackgroundCircle: string;
    NavigationTextColor: string;
    Sticky?: boolean;
}
//#endregion

//#region Component
/**
 * Navigation component that displays a logo and app name in a responsive layout
 * @param Props - The navigation props
 * @returns Navigation bar component
 */
export default function Navigation(Props:NavigationProps){
    return (
        <Row className={Props.Sticky ? "justify-content-md-center navigation-main navigation-sticky" : "justify-content-md-center navigation-main"} style={{backgroundColor: Props.BackgroundColour}}>
            <Col xs={2}>
                <div className="navigation-logo" style={{backgroundImage: "url("+Props.LogoImage+")", backgroundColor: Props.BackgroundCircle}}></div>
                <div className="navigation-name" style={{color: Props.NavigationTextColor}}> {Props.AppName} </div>
            </Col>
        </Row>
    );
}
//#endregion