import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./css/index.css";

import { StyledEngineProvider } from '@mui/material/styles';

import LoginPage from './pages/LoginPage';
import Homepage from './pages/Homepage';

import { SanityClient } from './client';

import { MapperInit } from './helpers/mappers/Mappers';

import ListOfTemplatesPage from './pages/serviceapp/ListOfTemplatesPage';
import CreateTemplatePage from './pages/serviceapp/CreateTemplatePage';
import EditTemplatePage from './pages/serviceapp/EditTemplatePage';

// Get using locale (url params)

const queryParams = new URLSearchParams(window.location.search);
const lang = queryParams.get('lang') || "en-US"; 

const SanityQuery = '*[localeCode=="'+lang+'"]';
var SanityData = [];

await SanityClient.fetch(SanityQuery)
	.then((data) => SanityData = data)
	.catch((error) => console.error('Sanity fetch error:', error));

// Fallback locale (english)

const DefaultQuery = '*[localeCode=="en-US"]';
var FallbackData = [];

if(lang !== "en_US"){
	await SanityClient.fetch(DefaultQuery)
		.then((data) => FallbackData = data)
		.catch((error) => console.error('Sanity fetch error:', error));
}

const router = createBrowserRouter([
	{
		path: "/login",
		element: <LoginPage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/",
		element: <Homepage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/serviceapp/template/list",
		element: <ListOfTemplatesPage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/serviceapp/template/new",
		element: <CreateTemplatePage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/serviceapp/template/edit",
		element: <EditTemplatePage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
]);

MapperInit();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<RouterProvider router={router}/>
		</StyledEngineProvider>
	</React.StrictMode>
);