import { IDataContract } from "../interfaces/IDataContract";
import { AutoMap } from "@automapper/classes";

export class TemplateContract implements IDataContract {
    @AutoMap()
    id : string = "";

    @AutoMap()
    name : string = "";

    @AutoMap(() => [SectionContract])
    sections : SectionContract[] = [];
}

export class SectionContract {
    @AutoMap()
    fieldName : string = "";

    @AutoMap()
    fieldType : string = "";
}