import { AutoMap } from "@automapper/classes";
import { SectionDto } from "./SectionDto";
import { v4 as uuidv4 } from 'uuid';

export class TemplateDto{
    @AutoMap()
    id : string = "";

    @AutoMap()
    name : string = "";

    @AutoMap(() => [SectionDto])
    sections : SectionDto[] = [];

    constructor(){
        this.id = uuidv4();
    }
}