//#region Imports
import React from "react";
import { Col, Row } from "react-bootstrap";
import "../css/LoginForm.css"
//#endregion

//#region Interfaces
interface LoginProps {
    BackgroundColor: string;
}
//#endregion

//#region Component
/**
 * LoginForm component that handles user authentication
 * @param Props - Component properties containing background color
 * @returns Login form with username, password and church code inputs
 */
export default function LoginForm(Props: LoginProps) {
    //#region Render
    return (
        <Row className="justify-content-center login-main">
            <Col xs={4}>
                <form className="d-flex justify-content-center form" action={""}>
                    <label className="d-flex username-label">Username</label>
                    <input className="username" />
                    <label className="d-flex password-label">Password</label>
                    <input className="password" />
                    <label className="d-flex church-code-label">Church Code</label>
                    <input className="church-code" />
                    <button className="login-button">Login</button>
                </form>
            </Col>  
        </Row>
    );
    //#endregion
}
//#endregion