//#region Imports
// React and Components
import React from "react";
import {Container} from "react-bootstrap";
import Navigation from "../components/Navigation";
import ApplicationsTable from "../components/ApplicationsTable";
import Footer from  "../components/Footer";

// Assets
import LogoImage from "../icon/temp/temp-icon-colour.png";

// Services
import { GetData } from "../client";

// Constants
import {
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_LITURGYAPPNAME 
} from "../helpers/GlobalConstants";
//#endregion

/**
 * Homepage component that serves as the main landing page
 * @param Props - Contains Data object with application configuration
 * @returns Homepage component with Navigation and Footer
 */
export default function Homepage(Props: any) {
    //#region Data Processing
    const data = Props.Data;
    const navigationAppName = GetData(data, NAVIGATION, NAVIGATION_LITURGYAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    //#endregion

    //#region Render
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#FFFFFF" 
                NavigationTextColor="#FFFFFF" 
                AppName="Liturgy App"></Navigation>
            <ApplicationsTable />
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#fffaeb"/>
        </Container>
    );
    //#endregion
}
