//#region Imports
// Material UI Components
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

// Icons
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

// Interfaces and Styles
import "../../css/common/table.css";
import { ICommonTableData } from '../../helpers/interfaces/ICommonTableData';
import { ICommonTableText } from '../../helpers/interfaces/LocaleObjects';
//#endregion

//#region Types & Interfaces
interface CommonTableProps{
    TableData: ICommonTableData[],
    LocaleText: ICommonTableText,
    StateSelectedRow: string[],
    SetStateSelectedRow: (selectedRow: string[]) => void
}
//#endregion

//#region Helper Functions
/**
 * Converts camelCase string to Header Case
 * @param key string in camelCase
 * @returns string in Header Case
 */
function camelCaseToHeader(key: string): string {
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
}
//#endregion

export default function CommonTable(Props: CommonTableProps) {
    //#region State & Constants
    const [selectedRow, setSelectedRow] = [Props.StateSelectedRow, Props.SetStateSelectedRow];
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('');
    const excludeKeys = ["id", "index"];
    //#endregion

    //#region Row Selection Handlers
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = Props.TableData.map((n) => n.id);
            setSelectedRow(newSelecteds);
            return;
        }
        setSelectedRow([]);
    };

    const handleClick = (id: string) => {
        const selectedIndex = selectedRow.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRow, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRow.slice(1));
        } else if (selectedIndex === selectedRow.length - 1) {
            newSelected = newSelected.concat(selectedRow.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRow.slice(0, selectedIndex),
                selectedRow.slice(selectedIndex + 1),
            );
        }

        setSelectedRow(newSelected);
    };

    const isSelected = (id: string) => selectedRow.indexOf(id) !== -1;
    //#endregion

    //#region Sorting Handlers
    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = React.useMemo(() => {
        return Props.TableData.sort((a, b) => {
            if (orderBy) {
                if (order === 'asc') {
                    return a[orderBy] < b[orderBy] ? -1 : 1;
                } else {
                    return a[orderBy] > b[orderBy] ? -1 : 1;
                }
            }
            return 0;
        });
    }, [Props.TableData, order, orderBy]);
    //#endregion

    //#region Render Methods
    const renderHeaderCells = (key: string, cellIndex: number) => {
        if(excludeKeys.includes(key)) return null;

        return (
            <TableCell 
                className="table-element-head-cell"
                key={cellIndex} 
                align={cellIndex === 1 ? "left" : "right"}
                onClick={() => handleRequestSort(key)}
                style={{ cursor: 'pointer' }}
            >
                {Props.LocaleText[key] ? Props.LocaleText[key] : camelCaseToHeader(key)}
                {orderBy === key 
                    ? (order === 'asc' 
                        ? <KeyboardArrowUpRoundedIcon/>
                        : <KeyboardArrowDownRoundedIcon/>)
                    : ''}
            </TableCell>
        );
    };

    const renderBodyCells = (row: any, key: string, cellIndex: number) => {
        if(excludeKeys.includes(key)) return null;

        return (
            <TableCell 
                className="table-element-body-cell"
                key={cellIndex} 
                align={cellIndex === 1 ? "left" : "right"}
            >
                {row[key]}
            </TableCell>
        );
    };
    //#endregion

    return (
        <TableContainer component={Paper} className={"table-element-main"}>
            <Table>
                <TableHead className="table-element-head">
                    <TableRow className="table-element-head-row">
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={selectedRow.length > 0 && selectedRow.length < Props.TableData.length}
                                checked={Props.TableData.length > 0 && selectedRow.length === Props.TableData.length}
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        {Props.TableData.length > 0 
                            ? Object.keys(Props.TableData[0]).map((key, cellIndex) => renderHeaderCells(key, cellIndex))
                            : ""}
                    </TableRow>
                </TableHead>
                <TableBody className="table-element-body">
                    {sortedData.map((row, i) => {
                        const isItemSelected = isSelected(row.id);
                        return (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                selected={isItemSelected}
                                id={row.id}
                                onClick={() => handleClick(row.id)}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onChange={() => handleClick(row.id)}
                                    />
                                </TableCell>
                                {Object.keys(row).map((key, cellIndex) => renderBodyCells(row, key, cellIndex))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}