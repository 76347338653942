//#region Imports
import React from 'react';
import { Container } from 'react-bootstrap';

// Asset imports
import LogoImage from "../icon/temp/temp-icon-colour.png";

// Component imports
import Navigation from "../components/Navigation";
import Footer from '../components/Footer';
import LoginForm from '../components/LoginForm';

// Utility imports
import { GetData } from "../client";

// Constants
import {
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_LITURGYAPPNAME 
} from "../helpers/GlobalConstants";
//#endregion

/**
 * LoginPage Component
 * Renders the main login page with navigation, login form, and footer
 * @param Props - Component properties containing application data
 */
export default function LoginPage(Props:any) {
    //#region Constants
    const data = Props.Data;
    const navigationAppName = GetData(data, NAVIGATION, NAVIGATION_LITURGYAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    //#endregion

    //#region Component Rendering
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#fffaeb" 
                NavigationTextColor="#fffaeb" 
                AppName={navigationAppName}></Navigation>
            <LoginForm BackgroundColor='White'></LoginForm>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#fffaeb"></Footer>
        </Container>
    )
    //#endregion
}

