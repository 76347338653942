import { IDataContract } from "../interfaces/IDataContract";
import { LoggingSeverity } from "../enums/LoggingSeverity";
import { Log } from "./LoggingService";

export async function Get <T extends IDataContract>(
    endpointUrl: string, 
    appName: string, 
    callName: string, 
    id: string = "") : Promise<T> 
{
    const secretKey = process.env.REACT_APP_API_SECRET_KEY;

    // Construct URL
    const url = `${endpointUrl}/${appName}/${callName}`
        + (
            id === "" 
                ? "" 
                : `?_id=${id}`
        );

    // Fetch data from constructed URL
    try {
        const response = await fetch(url);

        if (!response.ok) {
            Log(LoggingSeverity.Error, "WebService.Get()", "Error when trying to make GET call with data", `Status: ${response.status}, StatusText: ${response.statusText}, URL: ${url}`);
        }

        const data: T = await response.json();
        return data;
        
    } catch (error){
        Log(LoggingSeverity.Error, "WebService.Get()", "Error in response", `Message: ${(error as TypeError).message}, URL: ${url}`);
        throw error;
    }
}

export async function Post <T extends IDataContract>(
    endpointUrl: string, 
    appName: string, 
    callName: string, 
    data: IDataContract) : Promise<T> 
{
    const secretKey = process.env.REACT_APP_API_SECRET_KEY;

    // Construct URL
    const url = `${endpointUrl}/${appName}/${callName}`;

    // Fetch data from constructed URL
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            Log(LoggingSeverity.Error, "WebService.Post()", "Error when trying to make POST call with data", `Status: ${response.status}, StatusText: ${response.statusText}, URL: ${url}`);
        }

        const responseData: T = await response.json();
        return responseData;
        
    } catch (error){
        Log(LoggingSeverity.Error, "WebService.Post()", "Error in response", `Message: ${(error as TypeError).message}, URL: ${url}`);
        throw error;
    }
}

export async function Put <T extends IDataContract>(
    endpointUrl : string, 
    appName : string, 
    callName : string,
    data : IDataContract) : Promise<T> 
{
    const secretKey = process.env.REACT_APP_API_SECRET_KEY;

    // Construct URL
    const url = `${endpointUrl}/${appName}/${callName}`;

    // Fetch data from constructed URL
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            Log(LoggingSeverity.Error, "WebService.Put()", "Error when trying to make PUT call with data", `Status: ${response.status}, StatusText: ${response.statusText}, URL: ${url}`);
        }

        const responseData: T = await response.json();
        return responseData;
        
    } catch (error){
        Log(LoggingSeverity.Error, "WebService.Put()", "Error in response", `Message: ${(error as TypeError).message}, URL: ${url}`);
        throw error;
    }
}