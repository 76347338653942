import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import ApplicationButton from "./ApplicationButton";
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

// Please change the routes to the actual page links once the pages are ready, they are set to "/" and "/main" for now.
export default function ApplicationsTable() {
  return (
    <Container style={{ marginTop: '4em', marginBottom: '8em' }}>
      <Row className="justify-content-evenly">
        <Col xs={2}>
          <ApplicationButton Name="Service" Icon={LibraryBooksOutlinedIcon} Color="#E2FFE2" Route="/main"/>
        </Col> <Col xs={2}>
          <ApplicationButton Name="Bulletin" Icon={ChromeReaderModeOutlinedIcon} Color="#E2FFFF" Route="/main"/>
        </Col>
        <Col xs={2}>
          <ApplicationButton Name="Present" Icon={CoPresentOutlinedIcon} Color="#FFE2FF" Route="/"/>
        </Col>
      </Row>
    </Container>
  );
}
