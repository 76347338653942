import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import '../css/application-button.css';

interface ApplicationButtonProps {
    Name: string;
    Icon: React.ComponentType;
    Color: string;
    Route: string;
}

export default function ApplicationButton(Props: ApplicationButtonProps){
    const { Icon: Icon, Name, Color, Route } = Props;
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(Route);
    }
    return (
        <Container onClick={handleClick} className="application-button" style={{ backgroundColor: Color }}>
            <Icon />
            <span>{Name}</span>
        </Container>
    );
}
