// Sanity Type Definitions
export const NAVIGATION = "navigation";
export const NAVIGATION_LITURGYAPPNAME = "liturgyAppName";
export const NAVIGATION_BULLETINAPPNAME = "bulletinAppName";
export const NAVIGATION_SERVICEAPPNAME = "serviceAppName";

export const FOOTER = "footer";
export const FOOTER_CREDITTEXT = "creditText";

export const TEMPLATE_EDITOR = "templateEditor";
export const TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT = "templateSaveButtonText";
export const TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT = "templateCancelButtonText";
export const TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT = "templateNameLabelText";
export const TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE = "templateNameDefaultValue";
export const TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT = "templateCreateNewSectionLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT = "sectionFieldNameLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT = "sectionFieldTypeLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES = "sectionFieldTypeValues";

export const TEMPLATE_TABLE = "templateTable";
export const TEMPLATE_TABLE_BACKBUTTONARIA = "backButtonAria";
export const TEMPLATE_TABLE_ADDBUTTONARIA = "addButtonAria";
export const TEMPLATE_TABLE_EDITBUTTONARIA = "editButtonAria";
export const TEMPLATE_TABLE_DELETEBUTTONARIA = "deleteButtonAria";
export const TEMPLATE_TABLE_TABLENAMEHEADERTEXT = "tableNameHeaderText";
export const TEMPLATE_TABLE_TABLECREATEDONHEADERTEXT = "tableCreatedOnHeaderText";
export const TEMPLATE_TABLE_TABLETYPEHEADERTEXT = "tableTypeHeaderText";

// API Call Constants
export const API_SERVICE_APP = "serviceapp";
export const API_GET_TEMPLATE = "getTemplate";
export const API_CREATE_TEMPLATE = "createTemplate";
export const API_UPDATE_TEMPLATE = "updateTemplate";

// Route Constants
export const ROUTE_SERVICEAPP = "/serviceapp";

export const ROUTE_TEMPLATE = "/template";
export const ROUTE_LIST = "/list";
export const ROUTE_NEW = "/new";
export const ROUTE_EDIT = "/edit";

export const ROUTE_SERVICE = "/service";