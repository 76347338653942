//#region React Imports
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { Col, Row } from "react-bootstrap";
//#endregion

//#region CSS Imports
import "../../css/common/alert.css";
//#endregion

//#region Interfaces
/**
 * Props for the CommonAlert component
 * @param severity - Determines the alert type and color
 * @param variant - Visual style of the alert
 * @param alertText - Message to display in the alert
 * @param secondsBeforeClose - Optional duration before auto-closing
 * @param shouldClose - Optional flag to trigger auto-close
 */
interface CommonAlertProps {
    severity: "error" | "warning" | "info" | "success";
    variant: "standard" | "filled" | "outlined",
    alertText: string;
    secondsBeforeClose?: number;
    shouldClose?: boolean;
}
//#endregion

//#region Component Definition
/**
 * Common Alert component that displays messages with automatic close functionality
 * Supports different severities and variants with fade animations
 */
export default function CommonAlert(Props: CommonAlertProps){
    //#region State Constants
    const [showAlert, setShowAlert] = useState(true);
    const [fade, setFade] = useState("in");
    const [rowHeight, setRowHeight] = useState<string>("auto");
    const [rowPadding, setRowPadding] = useState<string>("1rem 4rem 0");
    //#endregion

    //#region Effects
    /**
     * Handle auto-close animation sequence:
     * 1. Fade out
     * 2. Reduce padding
     * 3. Reduce height
     * 4. Remove from DOM
     */
    useEffect(() => {
        if (Props.shouldClose && Props.secondsBeforeClose) {
            const timer = setTimeout(() => {
                setFade("out");
                setTimeout(() => {
                    setRowPadding("0");
                    setRowHeight("0px"); // Start shrinking the row height
                }, 500); // Match the fade-out animation duration
                setTimeout(() => {
                    setShowAlert(false);
                }, 1000); // Wait for height transition to complete
            }, Props.secondsBeforeClose * 1000);

            return () => clearTimeout(timer);
        }
    }, [Props.shouldClose, Props.secondsBeforeClose]);
    //#endregion 

    //#region Conditions
    // Early return if alert should not be shown
    if (!showAlert) {
        return null;
    }
    //#endregion

    //#region Render
    return (
        <Row className={`justify-content-center common-alert-main fade-${fade}`} style={{ height: rowHeight, overflow: 'hidden', padding: rowPadding }}>
            <Col xs={{span:8}} className="common-alert-sub">
                <Alert severity={Props.severity} variant={Props.variant}>
                    {Props.alertText}
                </Alert>    
            </Col>
        </Row>
    )
    //#endregion
}
//#endregion