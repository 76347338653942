//#region Imports
// React Imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

// Component Imports
import LogoImage from "../../icon/temp/temp-icon-colour.png";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import TemplateEditor from "../../components/serviceapp/TemplateEditor";

// Service and Helper Imports
import { GetData } from "../../client";
import { Mapper } from "../../helpers/mappers/Mappers";
import { UpdateTemplate } from "../../helpers/services/TemplateWebService";

// Type Imports
import { ITemplateEditorText } from "../../helpers/interfaces/LocaleObjects";
import { TemplateContract } from "../../helpers/contracts/TemplateContract";
import { TemplateDto } from "../../helpers/dtos/TemplateDto";
import { ResponseContract } from "../../helpers/contracts/ResponseContract";
import { TemplateActionResponseDto } from "../../helpers/dtos/TemplateActionResponseDto";

// Constants
import {
    API_SERVICE_APP,
    API_UPDATE_TEMPLATE,
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_SERVICEAPPNAME, 
    TEMPLATE_EDITOR,
    TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES,
    TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT,
    TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT,
    TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE,
    TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT,
    TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT,
    ROUTE_SERVICEAPP,
    ROUTE_TEMPLATE,
    ROUTE_LIST,
} from "../../helpers/GlobalConstants";
//#endregion

/**
 * EditTemplatePage Component
 * Allows users to edit existing templates with a form interface
 */
export default function EditTemplatePage(Props:any){
    //#region Setup and Configuration
    const location = useLocation();
    const navigate = useNavigate();
    const endpointUrl:string = String(process.env.REACT_APP_LITURGY_APP_BACKEND_URL);
    const templateContract = location.state as TemplateContract;
    //#endregion

    //#region State Management
    const [template, setTemplate] = useState<TemplateDto>(
        Mapper.map(templateContract, TemplateContract, TemplateDto) 
            || new TemplateDto()
    );
    //#endregion

    //#region Localization
    const data = Props.Data;
    const serviceAppName = GetData(data, NAVIGATION, NAVIGATION_SERVICEAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    const sectionFieldTypeValues = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES);
    const locale : ITemplateEditorText = {
        TemplateCancelButtonText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT),
        TemplateSaveButtonText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT),
        TemplateNameLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT),
        TemplateNameDefaultValue : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE),
        TemplateCreateNewFieldLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT),
        SectionFieldNameLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT),
        SectionFieldTypeLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT)
    }
    //#endregion

    //#region Event Handlers
    /**
     * Handles the template update operation
     * @param template The template to be updated
     */
    const updateTemplate = async (template: TemplateDto) => {
        var templateContract = Mapper.map(template, TemplateDto, TemplateContract);

        var apiResponse : ResponseContract = await UpdateTemplate(endpointUrl, API_SERVICE_APP, API_UPDATE_TEMPLATE, templateContract);
        var successMessage = apiResponse.success
            ? `Template named "${template.name}" was updated successfully`
            : `The system ran into issues when updating: "${template.name}"`;
        
        var response : TemplateActionResponseDto = {
            success : apiResponse.success, 
            message: successMessage
        };
        
        navigate(`${ROUTE_SERVICEAPP+ROUTE_TEMPLATE+ROUTE_LIST}`, {state : response});
    }
    //#endregion

    //#region Render
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#4b5648" 
                BackgroundCircle="#fffaeb" 
                NavigationTextColor="#fffaeb" 
                AppName={serviceAppName}/>
            <TemplateEditor 
                CancelButtonBehavior={() => window.location.href = ROUTE_SERVICEAPP+ROUTE_TEMPLATE+ROUTE_LIST} 
                SaveButtonBehavior={updateTemplate}
                LocaleText={locale}
                ListOfTypes={sectionFieldTypeValues}
                Template={template}
                SetTemplate={setTemplate}/>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#fffaeb"/>
        </Container>
    );
    //#endregion
}

