import { LoggingSeverity } from "../enums/LoggingSeverity";
import moment from 'moment-timezone';

export function Log(severity : LoggingSeverity, className : string, message : string, stacktrace : string = "") {
    const timestamp = moment().tz("America/Toronto").format();

    const logMessage = `${timestamp} - ${severity.toString()} - ${className} - ${message}. ${stacktrace}`;

    if(severity === LoggingSeverity.Error) {
        console.error(logMessage);
    } else if(severity === LoggingSeverity.Warning) {
        console.warn(logMessage);
    } else if(severity === LoggingSeverity.Debug) {
        if (process.env.REACT_APP_LITURGY_APP_DEBUG_MODE === "true") {
            console.log(logMessage);
        }
    } else {
        console.log(logMessage);
    }
}
