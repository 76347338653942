//#region Imports
// React and UI Components
import React, { useState } from "react";
import {Container} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Components
import LogoImage from "../../icon/temp/temp-icon-colour.png";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import TemplateEditor from "../../components/serviceapp/TemplateEditor";

// Services and Utilities
import { GetData } from "../../client";
import { Mapper } from "../../helpers/mappers/Mappers";
import { CreateTemplate } from "../../helpers/services/TemplateWebService";

// Constants
import {
    API_CREATE_TEMPLATE,
    API_SERVICE_APP,
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_SERVICEAPPNAME, 
    TEMPLATE_EDITOR,
    TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES,
    TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT,
    TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT,
    TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE,
    TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT,
    TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT,
    ROUTE_SERVICEAPP,
    ROUTE_TEMPLATE,
    ROUTE_LIST
} from "../../helpers/GlobalConstants";

// Types and Interfaces
import { ITemplateEditorText } from "../../helpers/interfaces/LocaleObjects";
import { TemplateContract } from "../../helpers/contracts/TemplateContract";
import { TemplateDto } from "../../helpers/dtos/TemplateDto";
import { TemplateActionResponseDto } from "../../helpers/dtos/TemplateActionResponseDto";
//#endregion

/**
 * CreateTemplatePage Component
 * Provides interface for creating new service templates
 * @param Props - Component properties containing locale data
 */
export default function CreateTemplatePage(Props:any){
    //#region Constants and Configuration
    const data = Props.Data;
    const endpointUrl:string = String(process.env.REACT_APP_LITURGY_APP_BACKEND_URL);
    const navigate = useNavigate();

    // UI Text Configuration
    const serviceAppName = GetData(data, NAVIGATION, NAVIGATION_SERVICEAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    const sectionFieldTypeValues = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES);
    //#endregion

    //#region State Management
    const [template, setTemplate] = useState(new TemplateDto());
    //#endregion

    //#region Locale Configuration
    const locale : ITemplateEditorText = {
        TemplateCancelButtonText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT),
        TemplateSaveButtonText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT),
        TemplateNameLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT),
        TemplateNameDefaultValue : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE),
        TemplateCreateNewFieldLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT),
        SectionFieldNameLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT),
        SectionFieldTypeLabelText : GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT)
    }
    //#endregion

    //#region Template Operations
    /**
     * Creates a new template and handles navigation after creation
     * @param template - The template data to be created
     */
    const createTemplate = async (template: TemplateDto) => {
        var templateContract = Mapper.map(template, TemplateDto, TemplateContract);

        var apiResponse = await CreateTemplate(endpointUrl, API_SERVICE_APP, API_CREATE_TEMPLATE, templateContract);
        var successMessage = apiResponse.success
                    ? `New template named "${template.name}" was created successfully`
                    : `The system ran into issues when creating new template: "${template.name}"`;
                
        
                var response : TemplateActionResponseDto = {
                    success : apiResponse.success, 
                    message: successMessage
                };

        navigate(`${ROUTE_SERVICEAPP+ROUTE_TEMPLATE+ROUTE_LIST}`, {state: response});
    }
    //#endregion

    //#region Render
    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#4b5648" 
                BackgroundCircle="#fffaeb" 
                NavigationTextColor="#fffaeb" 
                AppName={serviceAppName}/>
            <TemplateEditor 
                CancelButtonBehavior={() => window.location.href = ROUTE_SERVICEAPP+ROUTE_TEMPLATE+ROUTE_LIST} 
                SaveButtonBehavior={createTemplate}
                LocaleText={locale}
                ListOfTypes={sectionFieldTypeValues}
                Template={template}
                SetTemplate={setTemplate}/>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#fffaeb"></Footer>
        </Container>
    );
    //#endregion
}

