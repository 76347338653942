import { AutoMap } from "@automapper/classes";

export class SectionDto{
    id : string = "";
    index : number = 0;

    @AutoMap()
    fieldName : string = "";

    @AutoMap()
    fieldType : string = "";
}